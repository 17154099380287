import React, { useEffect, useState, useRef } from "react";
import {
  OverlayTrigger,
  Popover,
  Button,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import { getTokenOrRefresh } from "../services/Request";
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");
import { GiSpeaker } from "react-icons/gi";
import {
  MdFastForward,
  MdFastRewind,
  MdPlayCircle,
  MdPauseCircle,
} from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";
import { BsMicFill } from "react-icons/bs";
import { SliderInput } from "../components/UI/FormElements";
import { useCommon } from "./common-hooks";
import { useSettings } from "./setting-hooks";
import {
  languageCodeConstant,
  userSettingKeys,
} from "../utils/constants/constants";
import { useDeviceDetector } from "./device-detector-hooks";

export const useTextToSpeech = () => {
  const [audioTags, setAudioTags] = useState([]);
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(true);
  const [displayText, setDisplayText] = useState(null);
  const [textToSpeech, setTextToSpeech] = useState(null);
  const { isMobile } = useDeviceDetector();
  const audioRef = useRef();
  useEffect(() => {
    const init = async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
        setDisplayText("FATAL_ERROR: " + tokenRes.error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    return () => {
      audioTags?.map((audioTag) => {
        audioTag?.pause();
        audioTag?.remove();
      });
    };
  }, [audioTags]);

  // mic to text convertion
  const sttFromMic = async () => {
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromSubscription(
      tokenObj.speechKey,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );
    setDisplayText("speak into your microphone...");
    recognizer.recognizeOnceAsync((result) => {
      let _displayText;
      if (result.reason === ResultReason.RecognizedSpeech) {
        _displayText = `RECOGNIZED: Text=${result.text}`;
      } else {
        _displayText =
          "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.";
      }
      setDisplayText(_displayText);
    });
  };

  // if you want to generate text from audio file.
  const fileChange = async (event) => {
    const audioFile = event.target.files[0];
    console.log(audioFile);
    const fileInfo = audioFile.name + ` size=${audioFile.size} bytes `;
    setDisplayText(fileInfo);
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromSubscription(
      tokenObj.speechKey,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );
    recognizer.recognizeOnceAsync((result) => {
      let _displayText;
      if (result.reason === ResultReason.RecognizedSpeech) {
        _displayText = `RECOGNIZED: Text=${result.text}`;
      } else {
        _displayText =
          "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.";
      }
      setDisplayText(fileInfo + _displayText);
    });
  };

  // text to speech
  const synthesizeSpeech = async ({
    text,
    language,
    neuralVoice,
    audioTag,
  }) => {
    const readMeLoude = text ? text : textToSpeech;
    const speechSynthesisLanguage = language ? language : "en-US";
    const speechSynthesisVoiceName = neuralVoice
      ? neuralVoice
      : "en-US-JennyNeural";
    const audioConfig = speechsdk.AudioConfig.fromDefaultSpeakerOutput();
    if (readMeLoude) {
      const tokenObj = await getTokenOrRefresh();
      const speechConfig = speechsdk.SpeechConfig.fromSubscription(
        tokenObj.speechKey,
        tokenObj.region
      );
      speechConfig.speechSynthesisLanguage = speechSynthesisLanguage;
      speechConfig.speechSynthesisVoiceName = speechSynthesisVoiceName;
      const synthesizer = new speechsdk.SpeechSynthesizer(
        speechConfig,
        audioConfig
      );
      synthesizer.speakTextAsync(
        readMeLoude,
        (result) => {
          if (result) {
            synthesizer.close();
            return result.audioData;
          }
        },
        (error) => {
          console.log(error);
          synthesizer.close();
        }
      );
    }

    return audioConfig;
    // const blob = new Blob([audioSrc], { type: "audio/wav" });
    // if (audioTag) {
    //   audioTag.src = window.URL.createObjectURL(blob);
    //   audioTag.controls = true;
    // } else {
    //   console.log("else......................");
    //   audioRef.current.src = window.URL.createObjectURL(blob);
    // }
  };
  const renderAudioTag = React.useMemo(() => {
    return (
      <>
        <audio ref={audioRef} controls autoPlay style={{ display: "none" }} />
      </>
    );
  });

  // audio tag with icon
  const TTSAudioTag = (props) => {
    const iconSize = props?.iconSize ? props?.iconSize : `size-32`;
    const initIplayer = (speechText) => {
      setIsPlayerInitialized(false);
      audioTags?.map((audioTag) => {
        audioTag?.pause();
        audioTag?.remove();
      });
      setAudioTags([]);
      synthesizeSpeech({
        text: speechText,
        language: props?.language,
        neuralVoice: props?.neuralVoice,
      })
        .then((a) => {
          if (a?.privDestination?.privAudio) {
            const _audioTag = a?.privDestination?.privAudio;
            setAudioTags((audio) => {
              return [...audio, _audioTag];
            });
            _audioTag?.addEventListener("ended", () => {
              setIsPlayerInitialized(true);
            });
            _audioTag?.addEventListener("playing", () => {
              // once playing, you can enable player buttons
              setIsPlayerInitialized(true);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <Button
        variant="link"
        disabled={!isPlayerInitialized}
        onClick={() => {
          if (props?.text) {
            initIplayer(props?.text);
          }
        }}
      >
        <GiSpeaker className={`${iconSize} default-color tts-speaker`} />
      </Button>
    );
  };

  const TTSAudioPlayer = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const iconSize = props?.iconSize ? props?.iconSize : `size-32`;
    const [audioTag, setAudioTag] = useState(null);
    const { useNTNDebounce } = useCommon();
    const { saveUserSetting, getUserSetting, speechVoices } = useSettings();

    const _speechVoices = speechVoices?.filter(
      (v) => v.regionCultureCode === props?.language
    );
    const speechPlaybackSpeed = getUserSetting(
      userSettingKeys.SpeechPlaybackSpeed
    );

    const englishNeuralVoice = getUserSetting(
      userSettingKeys.SpeechEnglishVoiceName
    );
    const spanishNeuralVoice = getUserSetting(
      userSettingKeys.SpeechSpanishVoiceName
    );
    const neuralVoice =
      props?.language === languageCodeConstant.SPANISH
        ? spanishNeuralVoice
        : englishNeuralVoice;
    const [audioRate, setAudioRate] = useState(speechPlaybackSpeed || 0);
    const debouncedRate = useNTNDebounce(audioRate, 500);
    useEffect(() => {
      initIPlayer();
    }, []);

    const initIPlayer = (text) => {
      synthesizeSpeech({
        text: text,
        language: props?.language,
        neuralVoice: neuralVoice,
      })
        .then((a) => {
          if (a?.privDestination?.privAudio) {
            const _audioTag = a?.privDestination?.privAudio;
            setAudioTag(_audioTag);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    useEffect(() => {
      if (audioTag) {
        // audioTag?.addEventListener("durationchange", () => {
        //   let duration = audioTag.duration;
        // });
        audioTag?.addEventListener("ended", () => {
          setPlaying(false);
        });
        // audioTag?.addEventListener("pause", () => {
        //   // console.log("pause");
        //   // setPlaying(false);
        // });
        return () => {
          audioTag.pause();
          audioTag.remove();
        };
      }
    }, [audioTag]);

    const eventListeners = {
      playPrevious() {
        audioTag?.pause();
        audioTag?.remove();
        setCurrentIndex((pre) => {
          return currentIndex > 0 ? pre - 1 : 0;
        });
        setPlaying(true);
      },
      pauseMe() {
        audioTag?.pause();
        setPlaying(false);
      },
      playNext() {
        audioTag?.pause();
        audioTag?.remove();
        setCurrentIndex((pre) => {
          return props?.playlist?.length > currentIndex
            ? pre + 1
            : props?.playlist?.length - 1;
        });
        setPlaying(true);
      },
      playbackRateChange(playbackRate) {
        setAudioRate(playbackRate);
      },
    };

    useEffect(() => {
      if (debouncedRate) {
        saveUserSetting([
          {
            name: userSettingKeys.SpeechPlaybackSpeed,
            value: debouncedRate,
          },
        ]);
      }
    }, [debouncedRate]);

    useEffect(() => {
      if (audioTag) {
        if ("preservesPitch" in audioTag) {
          audioTag.preservesPitch = true;
        } else if ("mozPreservesPitch" in audioTag) {
          // deprecated
          audioTag.mozPreservesPitch = true;
        }
        audioTag.playbackRate = debouncedRate || speechPlaybackSpeed;
      }
    }, [audioTag, debouncedRate, speechPlaybackSpeed]);

    useEffect(() => {
      if (isPlaying) {
        const text = props?.playlist[currentIndex];
        initIPlayer(text);
      }
    }, [currentIndex, isPlaying, neuralVoice]);

    const initPlayer = () => {
      setPlaying(true);
    };

    const onSelectLang = (e) => {
      // console.log("e", e, "speechVoices", speechVoices);
      const selectedLang = _speechVoices?.find((sv) => sv.id == e);
      // console.log("selectedLang", selectedLang);
      if (selectedLang?.regionCultureCode === languageCodeConstant.ENGLISH) {
        saveUserSetting([
          {
            name: userSettingKeys.SpeechEnglishVoiceName,
            value: selectedLang.voiceName,
          },
        ]);
      } else {
        saveUserSetting([
          {
            name: userSettingKeys.SpeechSpanishVoiceName,
            value: selectedLang.voiceName,
          },
        ]);
      }
    };
    const playBackRatePopover = (
      <Popover id="tts-player-popover1" className="ttsplayer-popover1">
        <Popover.Content className="p-0">
          <SliderInput
            onChange={(e) =>
              eventListeners?.playbackRateChange(e?.target?.value)
            }
            min="0.50"
            max="1.50"
            step="0.05"
            defaultValue={audioRate || speechPlaybackSpeed}
          />
        </Popover.Content>
      </Popover>
    );

    const popover = (
      <Popover id="tts-player-popover" className="ttsplayer-popover">
        <Popover.Content className="p-0">
          <Row className="no-gutters p-1 align-center-center">
            <Col xs={"auto"} md={"auto"}>
              <Button
                onClick={() => eventListeners?.playPrevious()}
                variant="link"
                size="sm"
                className="verical-icon-button"
              >
                <MdFastRewind className={iconSize} />
                Rewind
              </Button>
            </Col>
            {!isPlaying ? (
              <Col xs={"auto"} md={"auto"}>
                <Button
                  onClick={() => initPlayer()}
                  variant="link"
                  className="verical-icon-button"
                  size="sm"
                >
                  <MdPlayCircle className={iconSize} />
                  Play
                </Button>
              </Col>
            ) : (
              <Col xs={"auto"} md={"auto"}>
                <Button
                  onClick={() => eventListeners?.pauseMe()}
                  variant="link"
                  className="verical-icon-button"
                  size="sm"
                >
                  <MdPauseCircle className={iconSize} />
                  Pause
                </Button>
              </Col>
            )}

            <Col xs={"auto"} md={"auto"}>
              <Button
                onClick={() => eventListeners?.playNext()}
                variant="link"
                className="verical-icon-button"
                size="sm"
              >
                <MdFastForward className={iconSize} />
                Fast Forward
              </Button>
            </Col>
            <Col xs={"auto"} md={"auto"}>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                // rootClose
                overlay={playBackRatePopover}
              >
                <Button
                  variant="link"
                  className="verical-icon-button"
                  size="sm"
                >
                  <RiTimerLine className={iconSize} />
                  Speed
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={"auto"} md={"auto"}>
              <Dropdown onSelect={(e) => onSelectLang(e)}>
                <Dropdown.Toggle
                  size="sm"
                  className="verical-icon-button"
                  variant="link"
                >
                  <BsMicFill className={iconSize} />
                  Voice
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {_speechVoices?.map((i) => {
                    return (
                      <Dropdown.Item
                        eventKey={i.id}
                        key={i.id}
                        active={neuralVoice === i.voiceName}
                        // href={`#${i.voiceName}`}
                      >
                        {i.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
    return (
      <>
        <OverlayTrigger
          trigger="click"
          placement={isMobile ? "bottom" : "right"}
          overlay={popover}
          defaultShow={props?.defaultShow}
        >
          <Button
            variant="link"
            size="sm"
            className="verical-icon-button no-padding-margin"
            onClick={() => props?.showPlayerHandler(!props?.defaultShow)}
            {...props}
          >
            <GiSpeaker className={`${iconSize} default-color tts-speaker`} />
            {props?.children}
          </Button>
        </OverlayTrigger>
      </>
    );
  };

  return {
    TTSAudioTag,
    TTSAudioPlayer,
    renderAudioTag,
    displayText,
    synthesizeSpeech,
    fileChange,
    sttFromMic,
    setTextToSpeech,
  };
};
